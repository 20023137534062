import * as React from "react"
import { Box } from "@chakra-ui/react"
import './JordanTastePaste.css';
import Creative from "../../components/Creative"
import backgroundJordan from "./images/jordan_background.jpg"
import jordanPackshotMint from "./images/jordan_packshotMint.png"
import jordanPackshotMango from "./images/jordan_packshotMango.png"
import jordanPackshotLukrecja from "./images/jordan_packshoLukrecja.png"
import jordanBlueBottom from "./images/jordan_bottomBlue.png"
import jordanLight from "./images/jordan_light.png"
import jordanPhoneShadow from "./images/jordan_phoneShadow.png"
import jordanBlueTop from "./images/jordan_topBlue.png"
import video from "./images/jordan_video.mp4"
import SimpleSidebar from "../../components/layout"

const JordanTastePaste = () => (
  <SimpleSidebar>
    <Creative
      className="jordanTastePaste"   
      video={video}
      background={backgroundJordan}
      backImage={jordanPhoneShadow}
      heading="Jordan TastePaste"
      tags={["choice"]}  
      tagBackground="#634da0"
      tagColor="#ffffff"
      headingColor="#ffffff"
    >     
      <Box className="yellowBackground"></Box>
      <img src={jordanBlueBottom} className="jordan_backgroundImage jordan_bottomBlue" />
      <img src={jordanBlueTop} className="jordan_backgroundImage jordan_topBlue" />
      <img src={jordanPackshotMango} className="jordan_image jordan_packshotMango" />
      <img src={jordanPackshotLukrecja} className="jordan_image jordan_packshotLukrecja" />
      <img src={jordanPackshotMint} className="jordan_image jordan_packshotMint" />
    </Creative>
    <img src={jordanLight} className="jordanLight"/>
  </SimpleSidebar>
)

export default JordanTastePaste
